import { Arrow } from "@components/UI"
import { Featured } from "@components/Work"
import { Hero } from "@components/Hero"
import { Layout } from "@components/Layout"
import React from "react"
import { Seo } from "@components/Seo"
import { splitText } from "@utils"
import { usePageSetup } from "@components/usePageSetup"

// markup
const WorkPage = () => {
  const { themeColor } = usePageSetup({ themeColor: "bg-blue-light" })
  const themeColorBefore = "before:bg-blue-light"
  const themeColorAfter = "after:bg-blue-light"

  return (
    <Layout>
      <Seo
        title="Work"
        description="At JK, we're driven to create work that makes an impact. These case studies show how we’ve helped B2B and B2C brands stand out, connect, and grow."
      />
      {/* HERO */}
      <section className={themeColor}>
        <Hero>
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "We treat storytelling",
                  break: "",
                  "text-gray-darkest": "like a team sport.",
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message>
            <p>
              We create end-to-end brand experiences, captivating creative, and thoughtful digital solutions, all
              conceived and developed in close collaboration with clients just like you.
            </p>
          </Hero.Message>
        </Hero>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-transparent" fillColor="text-transparent" />

      <section className="grid gap-y-clamp-16-28">
        <div className="relative overflow-hidden">
          <div className="container absolute inset-x-0 top-0">
            <div className={`aspect-w-1436 aspect-h-120 extend ${themeColorBefore} ${themeColorAfter}`}></div>
          </div>
          <div className="container relative grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-clamp-16-28">
            <Featured client="childrens" />
            <Featured client="bpm" />
            <Featured client="wb" />
            <Featured client="weichert" />
            <Featured client="braen" />
            <Featured client="ad" />
          </div>
        </div>
        <div className="relative overflow-hidden bg-teal-light">
          <div className="container absolute inset-x-0 top-0">
            <div className="aspect-w-1436 aspect-h-300 extend before:bg-white-dark after:bg-white-dark"></div>
          </div>
          <div className="container relative grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-clamp-16-7 pb-clamp-29-45">
            <Featured client="marcos" />
            <Featured client="lumendi" />
            <Featured client="ricoh" />
            <Featured client="xcelis" />
            <Featured client="ans" />
            <Featured client="marcoscsr" />
          </div>
        </div>
      </section>
      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
    </Layout>
  )
}

export default WorkPage
